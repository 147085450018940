import { collection, doc, getDoc, getDocs, increment, query, setDoc, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Link, Navigate, useParams } from "react-router-dom";
import { useThemeContext } from "../../../context/ThemeProvider";
import { useUserContext } from "../../../context/UserProvider";
import { db } from "../../../firebase";
import { RaceTitle } from "../../util/Components";
import { AccountIcon } from "../../util/Icons";
import ActivityFeed from "./ActivityFeed";
import { ActivityForm } from "./ActivityForm";

export default function DashboardPage() {
  const { user, userDetails, userRaces, linkedDevice } = useUserContext();
  const { colors } = useThemeContext();
  const [activityFeed, setActivityFeed] = useState([]);
  const [userTimesDoc, setUserTimesDoc] = useState(null);
  const [teamMembers, setTeamMembers] = useState(new Map());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showActivityForm, setShowActivityForm] = useState(false);
  const [totalDistance, setTotalDistance] = useState(0);
  const formRef = useRef();
  const fullscreenMedalRef = useRef();
  const { raceId } = useParams();
  const raceStravaCheck = userRaces?.get(`price_${raceId}`)?.stravaLink === true;

  const [raceDoc, setRaceDoc] = useState(null);

  const selectedRace = userRaces?.get(`price_${raceId}`);

  useEffect(() => {
    async function getRaceDoc() {
      const raceDocRef = await getDoc(doc(db, "races", `price_${raceId}`));
      if (raceDocRef.exists()) {
        setRaceDoc(raceDocRef.data());
      }
    }
    getRaceDoc();
    window.scrollTo(0, 0);
  }, []);

  const raceDistance = parseInt(selectedRace?.raceInfo?.distance);

  // const totalDistance = parseInt(selectedRace.raceInfo.distance) || 0; //activityFeed.reduce((total, current) => total + parseInt(current.value.distance), 0);

  //   console.log(teamMembers, teamMembersArray);
  async function addTimeToRace(e) {
    setIsSubmitting(true);
    const times = {};
    const newForm = Object.fromEntries(new FormData(formRef.current).entries());

    formRef.current.reset();

    times[`${new Date().getTime()}_${newForm.activityDate}`] = {
      activity: {
        date: `${newForm.activityDate}T12:00:00Z`,
        // hour: newForm.activityHour,
        // minute: newForm.activityMinute,
        // amPm: newForm.amPm,
      },
      distance: newForm.distance,
      distanceType: newForm.distanceType,
      duration: {
        hours: newForm.durationHours,
        minutes: newForm.durationMinutes,
        seconds: newForm.durationSeconds,
      },
    };
    if (totalDistance + parseFloat(newForm.distance) >= raceDistance) {
      //HAS COMPLETED RACE
      const completedDate = new Date().getTime();
      await setDoc(
        doc(db, "races", selectedRace.legId, "subscribedUsers", user.uid),
        { times, totalDistance: increment(newForm.distance), completedDate: completedDate },
        { merge: true }
      );
      const subscribedRaces = {};
      subscribedRaces[`${selectedRace.legId}`] = {};
      subscribedRaces[`${selectedRace.legId}`].completedDate = completedDate;
      await setDoc(doc(db, "users", user.uid), { subscribedRaces }, { merge: true });
    } else {
      //DIDN'T COMPLETE RACE
      await setDoc(
        doc(db, "races", selectedRace.legId, "subscribedUsers", user.uid),
        { times, totalDistance: increment(newForm.distance) },
        { merge: true }
      );
    }
    setTimeout(() => {
      setShowActivityForm(false);
      setIsSubmitting(false);
    }, 1500);
    getActivity();
    if (selectedRace?.teamName !== "noTeam") {
      getTeamMembers();
    }
  }

  async function getActivity() {
    if (!selectedRace) return;
    const activityRef = await getDoc(doc(db, "races", selectedRace.legId, "subscribedUsers", user.uid));
    let newActivity = [];
    if (activityRef.exists()) {
      if (activityRef.get("times")) {
        for (const [key, value] of Object.entries(activityRef.get("times"))) {
          newActivity.push({ key, value });
          // console.log(`${key}: ${value}`);
        }
      }
      //   setBibNumber(activityRef.data().bibNumber);
    }
    setActivityFeed(newActivity);
    setTotalDistance(activityRef.data().totalDistance || 0);
    setUserTimesDoc(activityRef);
  }
  async function getTeamMembers() {
    if (!selectedRace) return;
    const teamMembersDocs = await getDocs(
      query(collection(db, "races", selectedRace.legId, "subscribedUsers"), where("teamName", "==", selectedRace.teamName))
    );
    const teamMebmbersMap = new Map();
    teamMembersDocs.forEach((member) => {
      teamMebmbersMap.set(member.id, member.data());
    });
    setTeamMembers(teamMebmbersMap);
  }

  useEffect(() => {
    getActivity();
    if (selectedRace?.teamName !== "noTeam") {
      getTeamMembers();
    }
  }, [userRaces]);

  if (!!userRaces && !selectedRace) {
    return <Navigate to="/" replace={true} />;
  }
  if (!selectedRace) {
    return;
  }

  async function switchStravaCheck() {
    const hasStravaLink = userRaces.get(`price_${raceId}`)?.stravaLink === true;
    await updateDoc(doc(db, "users", user.uid), { [`subscribedRaces.price_${raceId}.stravaLink`]: !hasStravaLink });
  }

  const raceStart = new Date(selectedRace.raceInfo?.raceDateStart);
  const raceEnd = new Date(Date.parse(selectedRace.raceInfo?.raceDateEnd));
  const currentDate = new Date();

  const raceStarted = currentDate.getTime() - raceStart.getTime() > 0;
  const raceEnded = currentDate.getTime() - raceEnd.getTime() > 0;

  const raceActive = raceStarted && !raceEnded;

  // console.log(raceEnd)
  // const raceEnd = new Date("2023-03-19");
  // const diff = raceEnd.getTime() - currentDate.getTime();
  const diff = new Date(raceEnd.getTime() - currentDate.getTime());
  const diffAgo = new Date(currentDate.getTime() - raceEnd.getTime());
  const diffUntil = new Date(raceStart.getTime() - currentDate.getTime());

  // console.log(raceStarted, raceEnded);

  // let inputDefaultHour = currentDate.getHours() % 12;
  // inputDefaultHour = inputDefaultHour === 0 ? 12 : inputDefaultHour;
  // const inputDefaultMinute = currentDate.getMinutes();
  const inputDefaultValueString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, "0")}-${currentDate
    .getDate()
    .toString()
    .padStart(2, "0")}`;

  const inputMinDateString = `${raceStart.getFullYear()}-${(raceStart.getMonth() + 1).toString().padStart(2, "0")}-${raceStart
    .getDate()
    .toString()
    .padStart(2, "0")}`;

  const inputMaxDate = raceEnd - currentDate > 0 ? currentDate : raceEnd;
  const inputMaxDateString = `${inputMaxDate.getFullYear()}-${(inputMaxDate.getMonth() + 1).toString().padStart(2, "0")}-${inputMaxDate
    .getDate()
    .toString()
    .padStart(2, "0")}`;

  const daysLeft = Math.floor(diff.getTime() / (1000 * 60 * 60 * 24));
  const hoursLeft = Math.floor((diff.getTime() % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutesLeft = Math.floor((diff.getTime() % (1000 * 60 * 60)) / (1000 * 60));

  const daysAgo = Math.floor(diffAgo.getTime() / (1000 * 60 * 60 * 24));
  const hoursAgo = Math.floor((diffAgo.getTime() % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutesAgo = Math.floor((diffAgo.getTime() % (1000 * 60 * 60)) / (1000 * 60));

  const daysUntil = Math.floor(diffUntil.getTime() / (1000 * 60 * 60 * 24));
  const hoursUntil = Math.floor((diffUntil.getTime() % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutesUntil = Math.floor((diffUntil.getTime() % (1000 * 60 * 60)) / (1000 * 60));

  const teamMembersArray = [];
  let teamDistance = 0;
  teamMembers.forEach((member, id) => {
    teamDistance += parseFloat(member.totalDistance);
    teamMembersArray.push({ id: id, data: member });
  });
  if (teamDistance === 0) teamDistance = totalDistance;
  teamMembersArray.sort((a, b) => a.data.totalDistance < b.data.totalDistance);

  // function AddTp() {
  //   let newMem = teamMembers;
  //   let rand = Math.random();
  //   newMem.set(rand, {
  //     bibNumber: rand,
  //     firstName: "Tp",
  //     lastName: (rand * 10).toFixed(3),
  //     // times: {},
  //     totalDistance: (rand * 500 + 100).toFixed(2),
  //   });
  //   setTeamMembers((prev) => newMem);
  // }

  return (
    <div className="mx-auto max-w-3xl w-full mt-6">
      <RaceTitle selectedRace={selectedRace} />
      <div className="mb-3 pb-">
        <div className="grid grid-cols-2 gap-2 mt-3 mb-3">
          <div className=" grid grid-cols-[24px,1fr] gap-2 items-center">
            <AccountIcon />
            <div>
              <p className="mb-0">
                {userDetails.firstName} {userDetails.lastName} <span className="whitespace-nowrap">(BIB {selectedRace?.bibNumber})</span>
              </p>
            </div>
          </div>
          {!linkedDevice ||
            (linkedDevice === "none" && (
              <Link
                className="text-center whitespace-nowrap w-full h-min grid items-center hover:!bg-[#0F74C5] rounded-md text-sm font-medium text-white decoration-transparent px-1 py-2"
                to={"/account"}
                style={{ backgroundColor: colors.blue }}
              >
                Connect your Watch
              </Link>
            ))}
          {linkedDevice && linkedDevice !== "none" && (
            <Form.Group className="">
              <Form.Label className="h-full py-2 flex gap-1 flex-wrap align-items-center cursor-pointer" htmlFor="stravaCheck">
                <p className="m-0 text-center text-sm">Automatic Activities with your {linkedDevice}</p>
                <Form.Check
                  className="m-auto text-2xl"
                  name="stravaCheck"
                  id="stravaCheck"
                  type="switch"
                  reverse
                  checked={raceStravaCheck}
                  onChange={(e) => {
                    e.preventDefault();
                    switchStravaCheck();
                  }}
                />
              </Form.Label>
            </Form.Group>
          )}
        </div>

        {/* COMPLETED RACE BADGE */}
        {/* {selectedRace?.completedDate && selectedRace?.raceInfo?.name === "Celebrate Canada 2023" && (
          <div className="px-3">
            <Link to="https://mychallengecanada.ca/finisher/celebrate/" target="_blank">
              <img className="w-52" src={`${process.env.PUBLIC_URL}/img/CCC 2023 - FINISHER.png`} alt="" />
              <p>{`Create your finisher image ->`}</p>
            </Link>
          </div>
        )} */}
        {/* {true && ( */}
        {selectedRace?.completedDate && raceDoc?.finisher_badge && raceDoc?.finisher_link && (
          <div className="mb-3">
            <Link className="mx-auto" to={raceDoc?.finisher_link} target="_blank">
              <img className="w-52" src={raceDoc?.finisher_badge} alt="Finisher badge" />
              <span>{`Create your finisher image ->`}</span>
            </Link>
          </div>
        )}

        <div className="flex gap-2  justify-evenly">
          <div className="flex w-full min-h-12 justify-between items-center">
            <Button
              variant={raceActive && !selectedRace?.completedDate && "primary"}
              className={`text-white w-full !text-sm !font-medium py-2 shrink-0 text-center !px-1 ${
                (!raceActive || selectedRace?.completedDate) && "!bg-slate-500 active:!bg-slate-500 hover:!bg-slate-500"
              }`}
              onClick={(e) => {
                e.preventDefault();
                if (raceActive && !selectedRace?.completedDate) setShowActivityForm((prev) => !prev);
              }}
            >
              Add Activity Manually
            </Button>
          </div>
          <div className="flex w-full min-h-12 justify-between items-center">
            <Link
              className="text-center whitespace-nowrap w-full h-full grid items-center hover:!bg-[#0F74C5] rounded-md text-sm font-medium text-white decoration-transparent px-1 py-2"
              style={{ backgroundColor: colors.blue }}
              to={`/leaderboard/${raceId}`}
            >
              View Leaderboard
            </Link>
          </div>
        </div>
      </div>

      {/* {showActivityForm && (
        <ActivityForm
          addTimeToRace={addTimeToRace}
          formRef={formRef}
          inputMinDateString={inputMinDateString}
          inputMaxDateString={inputMaxDateString}
          isSubmitting={isSubmitting}
        />
      )} */}
      <Modal
        show={showActivityForm}
        onHide={() => setShowActivityForm(false)}
        className="!grid items-center"
        contentClassName="!border-none !bg-transparent"
      >
        <ActivityForm
          addTimeToRace={addTimeToRace}
          formRef={formRef}
          inputMinDateString={inputMinDateString}
          inputMaxDateString={inputMaxDateString}
          inputDefaultValueString={inputDefaultValueString}
          isSubmitting={isSubmitting}
          setShowActivityForm={setShowActivityForm}
        />
      </Modal>

      {/* OLD PERSONAL */}
      {/* <div className={`w-full mt-3 p-3 rounded-md text-white`} style={{ backgroundColor: colors.blue }}>
        <p className="font-medium">
          {userDetails.firstName} {userDetails.lastName}
        </p>
        <div className="grid grid-cols-3">
          <div>
            <p className="font-semibold">Distance so Far</p>
            <p className="mb-0">{totalDistance.toFixed(2)} km</p>
          </div>
          <div>
            <p className="font-semibold">Distance to Go</p>
            <p className="mb-0">{(parseInt(selectedRace.raceInfo?.distance) - totalDistance).toFixed(2)} km</p>
          </div>
          <div>
            {raceEnded ? (
              <>
                <p className="font-semibold">Ended Since</p>
                <p className="mb-0">
                  {daysAgo} days {hoursAgo} hours and {minutesAgo} minutes
                </p>
              </>
            ) : raceStarted ? (
              <>
                <p className="font-semibold">Days Left</p>
                <p className="mb-0">
                  {daysLeft} days {hoursLeft} hours and {minutesLeft} minutes
                </p>
              </>
            ) : (
              <>
                <p className="font-semibold">Time until start</p>
                <p className="mb-0">
                  {daysUntil} days {hoursUntil} hours and {minutesUntil} minutes
                </p>
              </>
            )}
          </div>
        </div>
      </div> */}
      {/* OLD TEAMS */}
      {/* {selectedRace.teamName !== "noTeam" && (
        <div className="w-full border-2 mt-3 p-3 rounded-md border-green-400">
          <p>Team {selectedRace.teamName}</p>
          <div className="grid grid-cols-3">
            <div>
              <p>Distance so Far</p>
              <p className="mb-0">{teamDistance.toFixed(2)}km</p>
            </div>
            <div>
              <p>Distance to Go</p>
              <p className="mb-0">{(raceDistance - teamDistance).toFixed(2)}km</p>
            </div>
            <div>
              {raceEnded ? (
                <>
                  <p className="font-semibold">Ended Since</p>
                  <p className="mb-0">
                    {daysAgo} days {hoursAgo} hours and {minutesAgo} minutes
                  </p>
                </>
              ) : raceStarted ? (
                <>
                  <p className="font-semibold">Days Left</p>
                  <p className="mb-0">
                    {daysLeft} days {hoursLeft} hours and {minutesLeft} minutes
                  </p>
                </>
              ) : (
                <>
                  <p className="font-semibold">Time until start</p>
                  <p className="mb-0">
                    {daysUntil} days {hoursUntil} hours and {minutesUntil} minutes
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      )} */}

      {/* {selectedRace.teamName !== "noTeam" && (
      <div className="w-full border-2 mt-3 p-3 rounded-md border-green-400">
        <p>Team Members</p>
        <div className="flex flex-col">
          {teamMembersArray.map((member) => {
            let memberDistance = parseFloat(member.data.totalDistance);
            const progress = (memberDistance * 100) / parseInt(selectedRace.raceInfo?.distance);
            return (
              <div className="grid grid-cols-3 gap-3" key={member.data.bibNumber}>
                <p>
                  {member.data.firstName} {member.data.lastName}
                </p>
                <p className="mb-0">{memberDistance.toFixed(2)} Km</p>
                <p className="mb-0">% {progress.toFixed(2)}</p>
              </div>
            );
          })}
        </div>
      </div>
    )} */}

      {/* {raceActive && (
        <h4 className="text-lg font-medium text-center w-full">
          {daysLeft} days {hoursLeft} hours and {minutesLeft} minutes left
        </h4>
      )} */}

      {/* CHALLENGE ENDED OR NOT STARTED TEXT */}
      {!raceActive && raceStarted && <h4 className="text-center">This challenge has ended.</h4>}
      {!raceStarted && (
        <p className="text-center mt-3 block m-0 text-green-700 font-medium">{`This challenge hasn't started yet. You can start uploading your activities on ${selectedRace?.raceInfo?.userStartDate}`}</p>
      )}

      {/* {selectedRace.teamName !== "noTeam" && <button onClick={AddTp}>Add Tp</button>} */}
      {selectedRace && userTimesDoc && (
        <div className="shadow">
          <ProgressComponent
            selectedRace={selectedRace}
            participants={selectedRace.teamName !== "noTeam" ? teamMembersArray : [{ id: userTimesDoc?.id, data: userTimesDoc?.data() }]}
          />
          {raceActive && (
            <div className="grid grid-cols-3 justify-items-center pb-3 px-1 gap-x-1">
              <p className="font-normal text-sm">Time Left</p>
              <p className="font-normal text-sm">Distance Left</p>
              <p className="font-normal text-sm">To Go</p>
              <div className="w-full shadowBox grid items-center py-2">
                <span className="m-0 text-xs text-center">
                  {daysLeft} Day / {hoursLeft} hrs / {minutesLeft} min
                </span>
              </div>
              <div className="w-full shadowBox grid items-center py-2">
                <span className="m-0 text-xs text-center">
                  {(parseInt(selectedRace.raceInfo?.distance) - teamDistance).toLocaleString(undefined, {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  km
                </span>
              </div>
              <div className="w-full shadowBox grid items-center py-2">
                <span className="text-xs  text-center">
                  {((parseInt(selectedRace.raceInfo?.distance) - teamDistance) / daysLeft / (teamMembersArray.length | 1)).toFixed(2)}
                  km / day / member
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {/* Personal Progress */}
      {/* {selectedRace.teamName === "noTeam" && (
        <PersonalProgress
          progressDistance={totalDistance}
          goalDistance={parseInt(selectedRace.raceInfo?.distance)}
          timeLeft={{ days: daysLeft, hours: hoursLeft, minutes: minutesLeft }}
          raceActive={raceActive}
        />
      )} */}

      {/* Team Progress */}
      {/* {selectedRace.teamName !== "noTeam" && (
        <TeamProgress
          progressDistance={teamDistance}
          goalDistance={parseInt(selectedRace.raceInfo?.distance)}
          teamName={selectedRace.teamName}
          teamMembers={teamMembersArray}
          timeLeft={{ days: daysLeft, hours: hoursLeft, minutes: minutesLeft }}
          raceActive={raceActive}
        />
      )} */}

      {typeof raceDoc?.milestones === typeof "" && fullscreenMedalRef.current && (
        <MilestonesFeed selectedRace={selectedRace} milestonesId={raceDoc.milestones} fullscreenMedalRef={fullscreenMedalRef} />
      )}

      <ActivityFeed activityFeed={activityFeed} getActivity={getActivity} selectedRace={selectedRace} totalDistance={totalDistance} />

      <div
        ref={fullscreenMedalRef}
        className="bg-slate-500 bg-opacity-50 fixed grid place-items-center top-0 inset-0 h-screen cursor-pointer py-3"
        style={{ display: "none" }}
        onClick={(e) => {
          if ((e.currentTarget === e.target) | true) {
            e.currentTarget.style.display = "none";
          }
        }}
      >
        <img className="mx-auto w-[calc(100vmin-24px)] h-[calc(100vmin-24px)] rounded-md anim-card" src="" />
      </div>
    </div>
  );
}

function MilestonesFeed({ selectedRace, milestonesId, fullscreenMedalRef }) {
  const milestones = selectedRace.milestones;
  const [milestonesData, setMilestonesData] = useState(null);

  useEffect(() => {
    async function getMilestonesDoc() {
      const milestonesDoc = await getDoc(doc(db, "milestones", milestonesId));
      if (!milestonesDoc.exists()) {
        return;
      }
      setMilestonesData(milestonesDoc.data());
    }
    getMilestonesDoc();
  }, []);
  return (
    <div className="w-full flex flex-col">
      <h3 className="mt-4">Milestones</h3>
      {milestonesData?.header && (
        <div key={milestonesData.header.name} className="flex flex-col mt-4 gap-0">
          <div className="flex gap-2 mb-1">
            <h6 className="font-semibold text-base mb-0">
              {milestonesData.header.name} - {milestonesData.header.location}
            </h6>
          </div>
          <div className="flex gap-2 ">
            {milestonesData.header?.image_url && (
              <img
                className="w-24 h-24 cursor-pointer rounded-sm"
                src={milestonesData.header.image_url}
                alt={`Milestone ${milestonesData.header.name} image`}
                onClick={(e) => {
                  const imageRef = fullscreenMedalRef.current.firstChild;
                  fullscreenMedalRef.current.style.display = "grid";
                  imageRef.src = milestonesData.header.image_url;
                  imageRef.alt = milestonesData.header.name;
                  imageRef.title = milestonesData.header.name;
                }}
              />
            )}
            <div className="w-full">
              {milestonesData.header?.description && <p className="m-0">{milestonesData.header?.description}</p>}
              {milestonesData.header?.link && (
                <a className="m-0 cursor-pointer break-all" href={milestonesData.header?.link} target="_blank" rel="noreferrer">
                  {milestonesData.header?.link}
                </a>
              )}
            </div>
          </div>
        </div>
      )}
      {typeof milestones === typeof {} &&
        typeof milestonesData?.milestones === typeof [] &&
        milestonesData.milestones.map((milestone) => {
          // console.log(milestone);
          if (milestones[milestone.name]) {
            return (
              <div key={milestone.name} className="flex flex-col mt-4 gap-0">
                <div className="flex gap-2 mb-1">
                  <h6 className="font-semibold text-base mb-0">
                    {milestone.name} - {milestone.location}
                  </h6>
                  <p className="m-0">{milestone.distance} km</p>
                </div>
                <div className="flex gap-2 ">
                  {milestone?.image_url && (
                    <img
                      className="w-24 h-24 cursor-pointer rounded-sm"
                      src={milestone.image_url}
                      alt={`Milestone ${milestone.name} image`}
                      onClick={(e) => {
                        const imageRef = fullscreenMedalRef.current.firstChild;
                        fullscreenMedalRef.current.style.display = "grid";
                        imageRef.src = milestone.image_url;
                        imageRef.alt = milestone.name;
                        imageRef.title = milestone.name;
                      }}
                    />
                  )}
                  <div className="w-full">
                    {milestone?.description && <p className="m-0">{milestone?.description}</p>}
                    {milestone?.link && (
                      <a className="m-0 cursor-pointer break-all" href={milestone?.link} target="_blank" rel="noreferrer">
                        {milestone?.link}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          }
          return;
        })}
    </div>
  );
}

function ProgressComponent({ selectedRace, participants }) {
  const isTeam = selectedRace.teamName !== "noTeam";
  const progressTabs = ["Total", "Last 7 Days", "Today"];
  const { colors } = useThemeContext();
  const [selectedTab, setSelectedTab] = useState(progressTabs[0]);
  const selectedTabIndex = progressTabs.indexOf(selectedTab);
  const contrastingColors = generateContrastingColors(participants.length);
  const progressTable = participants.map((participant, participantIndex) => {
    let data = participant.data;
    let totalProgress = data.totalDistance ? data.totalDistance : 0;
    let weekProgress = data.times
      ? Object.values(data.times).reduce((acc, time) => {
          let activityDate = new Date(time.activity.date);
          let activityDistance = parseFloat(time.distance);
          if (isWithinLast7Days(activityDate)) return acc + activityDistance;
          return acc;
        }, 0)
      : 0;
    let dailyProgress = data.times
      ? Object.values(data.times).reduce((acc, time) => {
          let activityDate = new Date(time.activity.date);
          let activityDistance = parseFloat(time.distance);
          if (isWithinLastDay(activityDate)) return acc + activityDistance;
          return acc;
        }, 0)
      : 0;
    let activitiesTable = data.times
      ? [
          Object.values(data.times).map((time) => {
            return { date: new Date(time.activity.date), distance: parseFloat(time.distance) };
          }),
          Object.values(data.times)
            .filter((time) => {
              return isWithinLast7Days(new Date(time.activity.date));
            })
            .map((time) => {
              return { date: new Date(time.activity.date), distance: parseFloat(time.distance) };
            }),
          Object.values(data.times)
            .filter((time) => {
              return isSameDay(new Date(time.activity.date));
            })
            .map((time) => {
              return { date: new Date(time.activity.date), distance: parseFloat(time.distance) };
            }),
        ]
      : [[], [], []];
    return {
      name: data.firstName + " " + data.lastName,
      firstName: data.firstName,
      lastName: data.lastName,
      progress: [totalProgress, weekProgress, dailyProgress],
      activities: activitiesTable,
      color: contrastingColors[participantIndex],
    };
  });

  const sortedProgressTable = progressTable.sort((a, b) => {
    return b.progress[selectedTabIndex] - a.progress[selectedTabIndex];
  });

  const totalDistance = progressTable.reduce((acc, current) => {
    return acc + parseFloat(current.progress[0]);
  }, 0);
  const weekDistance = progressTable.reduce((acc, current) => {
    return acc + current.progress[1];
  }, 0);
  const todayDistance = progressTable.reduce((acc, current) => {
    return acc + current.progress[2];
  }, 0);

  const distanceTable = [totalDistance, weekDistance, todayDistance];

  function isSameDay(date1, date2 = new Date()) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
  }

  function isWithinLastDay(date) {
    const now = new Date(); // Current date and time
    const sevenDaysAgo = new Date(now.getTime() - 1 * 24 * 60 * 60 * 1000); // 1 day ago from now
    return date >= sevenDaysAgo && date <= now;
  }
  function isWithinLast7Days(date) {
    const now = new Date(); // Current date and time
    const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago from now
    return date >= sevenDaysAgo && date <= now;
  }

  // Timeline setup
  const timelineCanvasRef = useRef();

  useEffect(() => {
    if (!timelineCanvasRef.current) return;
    let canvas = document.createElement("canvas");
    canvas = timelineCanvasRef.current;
    canvas.width = canvas.clientWidth;
    canvas.height = canvas.width / 4;
    let ctx = document.createElement("canvas").getContext("2d");
    ctx = canvas.getContext("2d");

    const raceStart = new Date(selectedRace.raceInfo.raceDateStart);
    const raceEnd = new Date(selectedRace.raceInfo.raceDateEnd);

    const timelineStart = raceStart;
    const timelineEnd = raceEnd.getTime() > Date.now() ? raceEnd : new Date();

    // console.log(timelineEnd.getTime() - timelineStart.getTime());
    // console.log(1 * 24 * 60 * 60 * 1000); // 1 day
    // console.log("days", (timelineEnd.getTime() - timelineStart.getTime()) / (1 * 24 * 60 * 60 * 1000));
    // console.log(canvas.width);

    for (let i = timelineStart.getTime(); i < timelineEnd.getTime(); i += 30 * 24 * 60 * 60 * 1000) {
      let x = getXCoord(i);
      ctx.fillRect(x, 0, 1, canvas.height);
    }

    function getXCoord(i) {
      let x = ((i - timelineStart.getTime()) * 100) / (timelineEnd.getTime() - timelineStart.getTime()) / 100;
      x *= canvas.width;
      return x;
    }
    progressTable.forEach((part) => {
      part.activities[0].forEach((time) => {
        let date = time.date;
        let x = getXCoord(date.getTime());
        let y = canvas.height - (time.distance / totalDistance) * canvas.height;
        ctx.fillRect(x, y, 2, 2);
      });
    });
  }, [timelineCanvasRef.current]);

  return (
    <div className=" py-3 rounded-md text-center">
      <div className="flex justify-center items-center gap-2">
        {!isTeam && <h5 className="font-semibold">Your</h5>}
        <h5 className="font-semibold">Progress</h5>
        {isTeam && <h5 className="">{selectedRace.teamName}</h5>}
      </div>
      <div className="flex justify-between px-2 gap-2">
        {progressTabs.map((text) => {
          return <ProgressButton key={text} text={text} active={selectedTab === text} setSelectedTab={setSelectedTab} />;
        })}
      </div>
      {/* <div className="flex flex-col">
        <p>Timeline</p>
        <div><canvas ref={timelineCanvasRef} className="w-full" /></div>
      </div> */}
      <div>
        {/* <h6 className="mt-2">
          {selectedTab} - {distanceTable[selectedTabIndex].toFixed(2)} km
        </h6> */}
        <div className="flex gap-x-0 flex-row h-[160px] mx-2 mt-3">
          {true && (
            <div
              className="flex flex-col gap-y-2 w-full overflow-y-scroll pr-2 pb-3 pt-1"
              style={{ scrollbarWidth: "thin", scrollbarColor: `${colors.blue} #D9D9D9` }}
            >
              {sortedProgressTable.map((participant) => {
                return (
                  <div
                    key={participant.name}
                    className="shadowBox grid grid-cols-2 align-middle px-2 py-1"
                    style={{ backgroundImage: `linear-gradient(to left, ${participant.color} 10%, transparent 65%)` }}
                  >
                    <span className="text-xs text-left overflow-hidden">{participant.firstName}</span>
                    <span className="text-xs  text-right grid items-center">
                      {parseFloat(participant.progress[selectedTabIndex]).toFixed(0)}km
                    </span>
                  </div>
                );
              })}
            </div>
          )}
          <div className="w-full grid items-center">
            {/* <ProgressCircleColored
              index={selectedTabIndex}
              // distanceTable={[parseFloat(selectedRace.raceInfo.distance), distanceTable[1], distanceTable[2]]}
              distanceTable={distanceTable}
              progressTable={sortedProgressTable}
            /> */}
            <ProgressCircleColoredCanvas
              index={selectedTabIndex}
              distanceTable={[parseFloat(selectedRace.raceInfo.distance), distanceTable[1], distanceTable[2]]}
              // distanceTable={distanceTable}
              progressTable={sortedProgressTable}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function ProgressButton({ text, setSelectedTab, active = false }) {
  return (
    <button
      className="w-full cursor-pointer px-1 py-2 rounded-md font-medium"
      style={{ backgroundColor: active ? "#D9D9D9D9" : "transparent" }}
      onClick={function () {
        setSelectedTab(text);
      }}
    >
      {text}
    </button>
  );
}

function generateContrastingColors(count) {
  if (count < 1) return [];
  const colors = [];
  const hueStep = 360 / count;
  for (let i = 0; i < count; i++) {
    const hue = i * hueStep;
    const color = `hsl(${hue}, 65%, 74%)`;
    colors.push(color);
  }
  return colors;
}

function PersonalProgress({ progressDistance = 0, goalDistance = 1, timeLeft = { days: 1, hours: 0, minutes: 0 }, raceActive }) {
  const localeOptions = { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const progressPercent = (progressDistance * 100) / goalDistance;
  const distanceLeft = goalDistance - progressDistance;
  return (
    <div className="shadow py-3 px-3 rounded-md text-center">
      <div className="flex items-stretch mb-3 max-w- mx-auto">
        <div className="w-full flex flex-col justify-center gap-1">
          <h5 className="w-full font-semibold">Your Progress</h5>
          <h5>{progressDistance.toLocaleString(undefined, localeOptions)} km</h5>
        </div>
        <ProgressCirlce progressPercent={progressPercent} />
      </div>
      {/* <h5>{distanceLeft.toLocaleString(undefined, localeOptions)} km left</h5> */}
      {raceActive && (
        <div className="grid grid-cols-3 justify-items-center">
          <h6 className="font-semibold">Time Left</h6>
          <h6 className="font-semibold">Distance Left</h6>
          <h6 className="font-semibold">To Go</h6>
          <div className="w-fit">
            <p className="m-0 text-left">{timeLeft.days} Days</p>
            <p className="m-0 text-left">{timeLeft.hours} Hours</p>
            <p className="m-0 text-left">{timeLeft.minutes} Minutes</p>
          </div>
          <div className="w-fit">
            <p className="m-0">{distanceLeft.toLocaleString(undefined, localeOptions)}km</p>
          </div>
          <div className="w-fit">{(distanceLeft / timeLeft.days).toFixed(2)}km/day</div>
        </div>
      )}
    </div>
  );
}

function TeamProgress({ progressDistance, goalDistance, teamMembers, teamName, timeLeft = { days: 1, hours: 0, minutes: 0 }, raceActive }) {
  // console.log(teamMembers);
  // for (let m = 0; m <= 1; m++) {
  //   teamMembers.push({
  //     id: Math.random(),
  //     data: {
  //       bibNumber: Math.random() * 100,
  //       firstName: "test",
  //       lastName: "name " + m,
  //       totalDistance: Math.random() * 200,
  //     },
  //   });
  // }
  // progressDistance = teamMembers.reduce((acc, member) => {
  //   return member.data.totalDistance + acc;
  // }, 0);
  // teamMembers.sort((a, b) => a.data.totalDistance < b.data.totalDistance);
  const localeOptions = { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const progressPercent = (progressDistance * 100) / goalDistance;
  const distanceLeft = goalDistance - progressDistance;
  return (
    <div className="shadow py-3 px-3 rounded-md text-center">
      <div className="mb-3 grid gap-x-3 grid-cols-1 sm:grid-cols-2 items-center grid-flow-dense">
        <div className="order-1 sm:col-span-2 mb-3">
          <h5 className="w-full font-semibold">Team Progress</h5>
          <h6 className="m-0">{teamName}</h6>
        </div>
        {/* <div className="w-full flex flex-col gap-1 sm:row-start-2 order-3"> */}
        {/* <div> */}
        {teamMembers.map((member) => {
          const data = member.data;
          const memberProgress = (data.totalDistance * 100) / goalDistance;
          return (
            <div key={data.bibNumber} className="border-t flex items-center justify-between px-1 pt-1 order-3">
              <span className="justify-self-start">
                {data.firstName} {data.lastName}
              </span>
              <div className="grid grid-rows-2">
                <span className="justify-self-end">{data.totalDistance.toLocaleString(undefined, localeOptions)}km</span>
                <span className="justify-self-end">{memberProgress.toLocaleString(undefined, localeOptions)}%</span>
              </div>
            </div>
          );
        })}
        {/* <hr></hr>
            <div className="grid grid-cols-2 ">
              <span className="justify-self-start">TOTAL</span>
              <span className="justify-self-end">{progressDistance.toLocaleString(undefined, localeOptions)}km</span>
            </div> */}
        {/* </div> */}
        {/* </div> */}
        <ProgressCirlce progressPercent={progressPercent} />
      </div>
      <hr />
      {raceActive && (
        <div className="grid grid-cols-3 gap-x-2 justify-items-center">
          <h6 className="font-semibold">Time Left</h6>
          <h6 className="font-semibold">Distance Left</h6>
          <h6 className="font-semibold">To Go</h6>
          <div className="w-fit">
            <p className="m-0 text-left">{timeLeft.days} Days</p>
            <p className="m-0 text-left">{timeLeft.hours} Hours</p>
            <p className="m-0 text-left">{timeLeft.minutes} Minutes</p>
          </div>
          <div className="w-fit">
            <p className="m-0">{distanceLeft.toLocaleString(undefined, localeOptions)}km</p>
          </div>
          <div className="w-fit">
            <p className="m-0">{(distanceLeft / timeLeft.days / teamMembers.length).toFixed(2)}km/day per member</p>
          </div>
        </div>
      )}
    </div>
  );
}

function ProgressCircleColoredCanvas({ index, distanceTable, progressTable }) {
  const canvasRef = useRef();
  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let canvasSize = Math.min(canvas.width, canvas.height);
    canvas.width = canvasSize;
    canvas.height = canvasSize;
    // ctx.fillStyle = "black";
    // ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.lineWidth = 18;
    let x = canvas.width / 2;
    let y = canvas.height / 2;
    let maxDistance = distanceTable[index];
    let lastAngle = -90;

    ctx.beginPath();
    ctx.strokeStyle = "#AAA3";
    ctx.arc(x, y, x - ctx.lineWidth / 2, toRad(0), toRad(360));
    ctx.stroke();

    let progressText = 0;

    for (let a = 0; a < progressTable.length; a++) {
      const participant = progressTable[a];
      const participantProgress = participant.progress[index] / maxDistance;
      progressText += parseFloat(participant.progress[index]);
      ctx.beginPath();
      ctx.strokeStyle = participant.color;
      ctx.fillStyle = participant.color;
      let nextAngle = participantProgress * 360 + lastAngle;
      nextAngle = Math.min(268, nextAngle);
      if (nextAngle === lastAngle) continue;
      ctx.arc(x, y, x - ctx.lineWidth / 2, toRad(lastAngle), toRad(nextAngle));
      ctx.stroke();
      // ctx.lineTo(x, y);
      // ctx.fill();
      if (nextAngle === 268) break;
      lastAngle += participantProgress * 360 + 2;
    }

    ctx.fillStyle = "black";
    ctx.font = "500 16px montserrat";
    ctx.textAlign = "center";
    ctx.fillText(progressText.toFixed(1) + " km", x, y + 8);

    if (index == 0) {
      if (distanceTable[0] !== 0) {
        let progressPercent = (progressText / distanceTable[0]) * 100;
        if (!isFinite(progressPercent)) {
          progressPercent = 0;
        }
        ctx.font = "500 12px montserrat";
        ctx.textAlign = "center";
        ctx.fillText(progressPercent.toFixed(1) + " %", x, y + 16 + 8);
      }
    }

    function toRad(deg) {
      return (deg * Math.PI) / 180;
    }
  }, [canvasRef.current, index]);
  return (
    <div className="w-full grid items-center">
      <canvas ref={canvasRef} className="mx-auto"></canvas>
    </div>
  );
}

function ProgressCircleColored({ index, distanceTable, progressTable }) {
  let maxDistance = distanceTable[index];
  return (
    <div className="w-full grid items-center">
      <style>
        {`@keyframes grow {
          from {
            stroke-dashoffset: -100;
          }

          to {
            stroke-dashoffset: ${Math.max(-100 - 100, -200)};
          }
        }
        `}
      </style>
      <svg className="mx-auto " width="30vmin" height="30vmin" viewBox="0 0 170 170" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="50%"
          cy="50%"
          r="40%"
          transform="rotate(-90, 85, 85)"
          stroke="#AAA"
          strokeWidth="10%"
          opacity={0.22}
          fill="none"
          pathLength="100"
          style={{
            strokeDasharray: "100 100",
            strokeDashoffset: Math.max(-100 - 100, -200),
          }}
        />
        {}
        {progressTable.map((participant, Pindex) => {
          const participantProgress = ((participant.progress[index] / maxDistance) * 100) | 0;
          let previousProgress = 0;
          for (let p = 0; p < Pindex; p++) {
            previousProgress += progressTable[p].progress[index];
          }
          previousProgress /= maxDistance;
          previousProgress = previousProgress ? previousProgress : 0;
          //console.log("participant ", Pindex);
          //console.log("prev progress", previousProgress);
          return (
            <circle
              key={participant.name + "-progress-circle"}
              cx="50%"
              cy="50%"
              r="40%"
              transform={`rotate(${previousProgress * 360 - 90}, 85, 85)`}
              stroke={participant.color}
              strokeWidth="10%"
              opacity={1}
              fill="none"
              pathLength="100"
              style={{
                strokeDasharray: "100 100",
                strokeDashoffset: Math.max(-100 - participantProgress, -200),
              }}
            />
          );
        })}
      </svg>
    </div>
  );
}

function ProgressCirlce({ progressPercent = 0 }) {
  const { colors } = useThemeContext();
  return (
    <div className="w-full grid items-center order-2 sm:col-start-2 sm:row-span-3">
      <style>
        {`@keyframes grow {
          from {
            stroke-dashoffset: -100;
          }

          to {
            stroke-dashoffset: ${Math.max(-100 - progressPercent, -200)};
          }
        }
        `}
      </style>
      <svg className="mx-auto " width="30vmin" height="30vmin" viewBox="0 0 170 170" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50%" cy="50%" r="40%" stroke="black" opacity={0.2} strokeWidth="10%" fill="none" />
        <circle
          cx="50%"
          cy="50%"
          r="40%"
          transform="rotate(-90, 85, 85)"
          stroke={colors.blue}
          strokeWidth="10%"
          opacity={0.5}
          fill="none"
          pathLength="100"
          style={{
            strokeDasharray: "100 100",
            strokeDashoffset: Math.max(-100 - progressPercent, -200),
            // strokeDashoffset: -100,
            // rotate: "-90deg",
            // transformOrigin: "center",
          }}
        />
        <circle
          cx="50%"
          cy="50%"
          r="40%"
          transform="rotate(-90, 85, 85)"
          stroke={colors.blue}
          strokeWidth="10%"
          fill="none"
          pathLength="100"
          style={{
            strokeDasharray: "100 100",
            // strokeDashoffset: Math.max(-100 - progressPercent, -200),
            strokeDashoffset: -100,
            // rotate: "-90deg",
            // transformOrigin: "center",
            animation: `grow 5000ms ease-out 1s forwards`,
          }}
        />
        <text
          x="50%"
          y="50%"
          dy="0.25rem"
          dominantBaseline="middle"
          textAnchor="middle"
          fill="black"
          fontSize="1.25rem"
          className="leading-none font-bold"
        >
          {progressPercent.toFixed(1)}%
        </text>
      </svg>
    </div>
  );
}
